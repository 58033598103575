body {
  margin: 0;
  font-family: 'finlandica', sans-serif;
}

@font-face {
  font-family: 'finlandica';
  src: url('./fonts/finlandica-boldWeb.woff2') format('woff2'), url('./fonts/finlandica-boldWeb.woff') format('woff'),
    url('./fonts/finlandica-boldWeb.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'finlandica';
  src: url('./fonts/finlandica-bolditalicWeb.woff2') format('woff2'),
    url('./fonts/finlandica-bolditalicWeb.woff') format('woff'),
    url('./fonts/finlandica-bolditalicWeb.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'finlandica';
  src: url('./fonts/finlandica-italicWeb.woff2') format('woff2'),
    url('./fonts/finlandica-italicWeb.woff') format('woff'), url('./fonts/finlandica-italicWeb.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'finlandica';
  src: url('./fonts/finlandica-regularWeb.woff2') format('woff2'),
    url('./fonts/finlandica-regularWeb.woff') format('woff'),
    url('./fonts/finlandica-regularWeb.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'finlandica';
  src: url('./fonts/finlandica-regularWeb.woff2') format('woff2'),
    url('./fonts/finlandica-regularWeb.woff') format('woff'),
    url('./fonts/finlandica-regularWeb.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Squada One';
  src: url('./fonts/SquadaOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
